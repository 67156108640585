<template>
    <div>
        <br>
        <PostContainer :headless="true">
            <v-row justify="center">
                <v-col cols="12" sm="6" md="4" v-for="post in news" :key="post.id"> 
                    
                    <v-row
                        align="center"
                        justify="center" no-gutters>
                        <Card :item="post" btnLabel="Ver"/>
                    </v-row>
                </v-col>
            </v-row>

            <br>
            <div class="text-center" v-if="hasMore">
                <v-btn @click="fetchMoreNews" outlined rounded :loading="loading">
                    <font-awesome-icon icon="redo-alt" /> <span> Mostrar Mais</span>
                </v-btn>
            </div>
        </PostContainer>
    </div>
</template>

<script>
import PostContainer from '../components/PostContainer';
import Card from '../components/Card';

import { NEWS } from '../routes';

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data: () => ({}),
    created () {
        this.fetchNews({ limit: 12, });
    },
    computed: {
        ...mapGetters('news', ['hasMore']),
        ...mapState('news', ['news', 'hasMore', 'loading']),
        newsRoute() {
            return NEWS;
        }
    },
    methods: { 
        ...mapActions('news', {
            fetchNews: 'list',
            loadMore: 'loadMore'
        }),
        fetchMoreNews: function () {
            this.loadMore({ limit: 12 })
        },
    },
    components: {
        PostContainer,
        Card,
    },
    
    
}
</script>